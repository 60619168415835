// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-flota-js": () => import("./../../../src/pages/flota.js" /* webpackChunkName: "component---src-pages-flota-js" */),
  "component---src-pages-franczyza-js": () => import("./../../../src/pages/franczyza.js" /* webpackChunkName: "component---src-pages-franczyza-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obsluga-wypadkow-js": () => import("./../../../src/pages/obsluga-wypadkow.js" /* webpackChunkName: "component---src-pages-obsluga-wypadkow-js" */),
  "component---src-pages-uszczerbek-na-zdrowiu-js": () => import("./../../../src/pages/uszczerbek-na-zdrowiu.js" /* webpackChunkName: "component---src-pages-uszczerbek-na-zdrowiu-js" */),
  "component---src-pages-uszkodzony-pojazd-js": () => import("./../../../src/pages/uszkodzony-pojazd.js" /* webpackChunkName: "component---src-pages-uszkodzony-pojazd-js" */),
  "component---src-pages-wspolpraca-js": () => import("./../../../src/pages/współpraca.js" /* webpackChunkName: "component---src-pages-wspolpraca-js" */)
}

